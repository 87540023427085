$(window).on('load resize', function () {
  // コンテンツの高さ調整
  // header footerの高さを取得
  var headerHeight = $("#site-header").outerHeight(true);
  var footerHeight = $("#site-footer").outerHeight(true);
  var windowHeight = document.documentElement.clientHeight;
  var minHeight = windowHeight - headerHeight - footerHeight;
  $(".wrapper").css('min-height', minHeight);
});

$(function () {
  // ヘッダー ドロップダウンメニュー
  $("#site-header li.has-child-manu").on("click", function () {
    $(this).find(".child-menu").slideToggle();
  });
  // サイドパネルの開閉
  // $("#side-panel .menu-btn").on("click", function () {
  //   if ($('body').hasClass('small-panel')) {
  //     $("body").addClass('open-panel');
  //     $("body").removeClass('small-panel');
  //     Cookies.set("SIDE_PANEL_" + $("body").attr("system_id"), "open-panel");
  //   } else if ($('body').hasClass('open-panel')) {
  //     $(".child-menu").css('display', 'none');
  //     $("body").removeClass('open-panel');
  //     $("body").addClass('small-panel');
  //     Cookies.set("SIDE_PANEL_" + $("body").attr("system_id"), "small-panel");
  //     var sidemenu_cookies = document.cookie.split(';');
  //     for(var c of sidemenu_cookies){
  //         var cArray = c.split('=');
  //         var pattern = /SIDE-MENU-.*/;  
  //         if( cArray[0].match(pattern) ){  
  //           Cookies.remove(cArray[0]);
  //         }
  //     }
  //   }
  // });
  // サイドパネル ドロップダウンメニュー
  $("#side-navigation .has-child-manu a.nav-link").on("click", function () {
    $(this).toggleClass('open');
    var childmenu = $(this.parentElement).find(".child-menu")
    childmenu.slideToggle(function(){
      var no = childmenu.attr("no")
      if ($(this).is(':visible')) {  
        Cookies.set("SIDE-MENU-" + no,"open");
      } else {
        Cookies.remove("SIDE-MENU-" + no);
      }
    });
  });

  $(function() {
    if ($("body").attr("system_id") != undefined){
      // if ($('body').hasClass('small-panel')) {
      //   Cookies.set("SIDE_PANEL_" + $("body").attr("system_id"), "small-panel");
      // }else{
      //   Cookies.set("SIDE_PANEL_" + $("body").attr("system_id"), "open-panel");
      // }
      Cookies.set("SIDE_PANEL_" + $("body").attr("system_id"), "open-panel");
    }
  });
  

  // 絞り込み検索
  $(".btn-filter").on("click", function () {
    $(".search-box").toggleClass('close');
  });
  // 一覧画面 中項目以下 表示・非表示切り替え
  $(".btn_panel-swich").on("click", function () {
    $(this).toggleClass('close');
  });
  // submit処理
  $('button[type="submit"]').on("click", function() {
    $(this).parents('form').attr('action', $(this).data('action'));
    $(this).parents('form').attr('method', $(this).data('method'));
    $(this).parents('form').on("submit");
  });

  //Inputフォーカス時にEnterキー無効
  $("input").on("keydown", function(e) {
    if (e.key === "Enter") {
        return false;
    } else {
        return true;
    }
  });

  //ヘルプ画面をポップアップウインドウで開く
  $(".help").on("click", function () {
    let width = $("#help_width").val();
    let height = $("#help_height").val();

    window.open(this.href, null, 'width=' + width + ', height=' + height);
  });
});